@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dcd0d0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.tag {
  transform: rotateX(180deg) rotateZ(180deg)
}

.reversed-text {
  transform: rotateY(180deg);
}




/*   */

.maxwidth66 {
  width: 66.6%;
}

.max-33 {
  width: 33.33%;
}

.resp-img-wrap {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.page-title-1 {
  align-self: center;
  justify-self: center;

  position: relative;
  background: 0 0;
  top: unset;
  ;
  left: unset;
  transform: none;
  width: 100%;
  height: fit-content;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  padding-left: 60px;
  padding-right: 60px;
}

.animated-link--item {
  background-color: transparent !important;
  background-image: linear-gradient(to top, currentColor 0, currentColor 0), linear-gradient(to top, transparent 0, transparent 0);
  background-repeat: no-repeat;
  background-position: left bottom, left bottom;
  background-size: 0 1px, 100% 1px;
  transition-property: background-size;
  transition-timing-function: ease;
  transition-duration: .25s;

}

.animated-link--item:hover {
  background-position: left bottom, left bottom;
  background-size: 100% 1px, 100% 1px;

}

.product-section-1 {
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;

}

.collage-builder_grid-container {
  margin: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: inherit;
}

.collage-builder_grid-item {
  width: 66.667%;
  padding-bottom: 33.333%;
  height: 0;
  position: relative;
}

.collage-builder_grid-item--inner {
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;

}

.collage-builder-content {
  width: 100%;
  height: 100%;
}

.collage-builder_media-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.collage-builder_text-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
}

.collage-builder_text-heading {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.1rem;
  ;
  hyphens: auto;
  overflow-wrap: anywhere;

}

.collage-builder_bg-video {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ;
  overflow: hidden;

}

.collage-builder_grid-item2 {
  width: 33.3333%;
  padding-bottom: 33.333%;
  height: 0;
  position: relative;
  box-sizing: inherit;
}

.collage-builder_grid-item--inner2 {
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;

}

.collage-builder_text-container2 {
  justify-content: flex-end;
  ;
  align-items: flex-end;
  text-align: right;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
}

.collage-builder_heading2 {
  font-size: 29px;
  margin-bottom: 0px;
  line-height: 1.1rem;
  ;
  hyphens: auto;
  overflow-wrap: anywhere;
}

.collage-builder_text-content {
  margin-top: 15px;
  margin-bottom: 0;
  margin: 0 0 15px 0;

}

.facebook1 {
  align-items: center;
  justify-content: flex-start;
  ;
  flex-direction: row;
  ;
  position: relative;
  min-height: 44px;
  border-radius: 5px;

}

.facebook1:hover {
  background-color: lightgray;
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.three-dots:hover {
  background-color: lightgray;
}

.slide-x:hover {
  background-color: lightgray;

}

.hover-bounce:hover {

  transform: translateY(-1rem);
}

.para1-bg-img {
  background-image: url('/leo_ac_done.JPG');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.besteagle{
  margin-top: calc(-100vw* 38 / 535);;
  /* padding-top: calc(321px); */
  margin-bottom: calc(516px - 116px + 60px);
  padding-bottom: calc(116px);
  position: relative;
  z-index: 11;
  ;
} 

.besteagle::before {
  background-image: url("https://sierracoolslv.com/wp-content/uploads/2021_Best_of_Las_Vegas.png");
  position: absolute;
  top: calc(0px - 13px);
  left: calc(100% - 280px - 42px);
  right: 0;
  background-position: right center;
  content: "";
  width: 280px;
  height: 280px;
  background-repeat: no-repeat;
  background-size: contain;
  transform: none;
  display: block;
}

.besteagle::after {
  content: "";
  display: block;
}


.button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.servicetag:hover {
  animation: vibrate 0.5s;
}

@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-2px, 0);
  }
  20% {
    transform: translate(2px, 0);
  }
  30% {
    transform: translate(-2px, 0);
  }
  40% {
    transform: translate(2px, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}