
.hero-section {
    height: 90vh;
    /* background-image: url('/leoacDoneSmall.PNG'); */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .hero-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 10px;
  }
  
  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .contact-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #0056b3;
  }

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }