.navbar {
  background-color: #333;
  color: white;
  transition: all 0.3s ease-in-out;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* .logo {
  font-size: 1.5rem;
  font-weight: bold;
} */

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links a {
  /* color: white; */
  text-decoration: none;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.invis {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
  background-color: rgba(255, 255, 128, .05);
  ;
}

.noshow{
  display: none;
}